<template>
  <div>
    <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="showSpinner"/>
    <div class="otherPropertyDetail" v-if="propertyDetailObject.listingType == 'half_way_or_shared' || propertyDetailObject.listingType == 'mental_or_detox_facility' || propertyDetailObject.listingType == 'free_or_public_shelter'">
      <div class="additionalDetailWrapper">
        <b-row>
          <h3>Additional Details</h3>
          <div class="dividerBottom"></div>
        </b-row>
        <b-row>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Fullname</h5>
            <p style="text-transform: capitalize !important">
              {{
                propertyDetailObject.Fullname ? propertyDetailObject.Fullname : "N/A"
              }}
            </p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
              <h5 class="bold">Entry Fee</h5>
              <p>
              {{
                propertyDetailObject.entryFee
                  ? propertyDetailObject.entryFee
                  : "N/A"
              }}
              </p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
              <h5 class="bold">Acceptable Payment Methods</h5>
              <p>
              {{
                propertyDetailObject.paymentMethod
                  ? propertyDetailObject.paymentMethod.join(', ')
                  : "N/A"
              }}
              </p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
              <h5 class="bold">Age</h5>
              <p>
              {{
                propertyDetailObject.age
                  ? propertyDetailObject.age.join(', ')
                  : "N/A"
              }}
              </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="3" lg="3" md="3">
              <h5 class="bold">Gender(s) Admitted</h5>
              <p>
              {{
                propertyDetailObject.gender
                  ? propertyDetailObject.gender.join(', ')
                  : "N/A"
              }}
              </p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
              <h5 class="bold">Length of Stay</h5>
              <p>
              {{
                propertyDetailObject.los
                  ? propertyDetailObject.los.join(', ')
                  : "N/A"
              }}
              </p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
              <h5 class="bold">Bathroom Type</h5>
              <p>
              {{
                propertyDetailObject.bathroomType
                  ? propertyDetailObject.bathroomType
                  : "N/A"
              }}
              </p>
          </b-col>
        </b-row>
      </div>
      <div class="internalUseWrapper">
        <b-row>
          <h3>Internal Use Only Details</h3>
          <div class="dividerBottom"></div>
        </b-row>
        <b-row>
          <b-col xl="3" lg="3" md="4">
            <h5 class="bold">What is the EXTERNAL nickname for this shelter location?</h5>
            <p v-if="propertyDetailObject.nameOfOwner">{{ propertyDetailObject.nameOfOwner }}</p>
            <p v-else>N/A</p>
          </b-col>
          <b-col xl="3" lg="3" md="4">
            <h5 class="bold">Who is the best point of contact?</h5>
            <p v-if="propertyDetailObject.pointOfContact">{{ propertyDetailObject.pointOfContact }}</p>
            <p v-else>N/A</p>
          </b-col>
          <b-col xl="3" lg="3" md="4">
            <h5 class="bold">What is the best phone number for the point of contact?</h5>
            <p style="text-transform: capitalize !important">
              {{
                propertyDetailObject.phoneNumberForpoc
                  ? propertyDetailObject.phoneNumberForpoc
                  : "N/A"
              }}
            </p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OtherPropertyDetail from "@/views/admin/listing-management/otherPropertyDetail";
import LandingInformation from "@/views/admin/listing-management/landingInformation";
import AskingPriceAndIncomePotential from "@/views/admin/listing-management/askingPrice&IncomePotential";
import PhotosVideosDocumentation from "@/views/admin/listing-management/photosvideiosDocumentation";
import SubscriptionDetail from "@/views/admin/listing-management/subscriptiondetail";

export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    CardStatisticProfitChart,
    ToastificationContent,
    OtherPropertyDetail,
    LandingInformation,
    AskingPriceAndIncomePotential,
    PhotosVideosDocumentation,
    SubscriptionDetail,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: false,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "UserId",
          label: "User ID",
        },
        {
          key: "FullName",
          label: "Full Name",
        },
        {
          key: "CompanyName",
          label: "Company Name",
        },
        {
          key: "UserEmail",
          label: "User Email",
        },
        {
          key: "UserMobileNumber",
          label: "User Mobile Number",
        },
        {
          key: "UserType",
          label: "User Type",
        },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "Action",
          label: "Action",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      userType: null,
      status: null,
      propertyDetailObject: {},
      activeList: "Other Property Details",
      updatedStatus: {},
    };
  },

  created() {
    var self = this;
    self.showSpinner = true;
    self.propertyDetailObject = {};
    self.getUserData().then(() => {
      self
        .idSpecificPropertyData(self.$route.params.id)
        .then(() => {
          var index = self.allUserData.findIndex((item) => {
            return item.id == self.getIdSpecificPropertyArray[0].userId;
          });
          
          // console.log("propertyStatusArray", self.propertyStatusArray);
          // if (
          //   self.getIdSpecificPropertyArray[0].listingType ===
          //   "free_or_public"
          // ) {
          //   self.propertyDetailObject = {
          //     id: self.getIdSpecificPropertyArray[0].id,
          //     Fullname:
          //       index != -1
          //         ? `${self.allUserData[index].firstName} ${self.allUserData[index].lastName}`
          //         : "",
          //     Companyname:
          //       index != -1 ? self.allUserData[index].companyName : "",
          //     Contactnumber:
          //       index != -1 ? self.allUserData[index].phoneNumber : "",
          //     Latitude: self.getIdSpecificPropertyArray[0].contractBLatitude,
          //     Longitude: self.getIdSpecificPropertyArray[0].contractBLongitude,
          //     Roof: self.getIdSpecificPropertyArray[0].contractBRoof,
          //     HAVC: self.getIdSpecificPropertyArray[0].contractBHVAC,
          //     Flooring: self.getIdSpecificPropertyArray[0].contractBFlooring,
          //     Windows: self.getIdSpecificPropertyArray[0].contractBWindows,
          //     Plumbing: self.getIdSpecificPropertyArray[0].contractBPlumbing,
          //     Electrical:
          //       self.getIdSpecificPropertyArray[0].contractBElectrical,
          //     Parkingfeature:
          //       self.getIdSpecificPropertyArray[0].contractBParkingFeatures,
          //     Foundation:
          //       self.getIdSpecificPropertyArray[0].contractBFoundation,
          //     Sewerinformation:
          //       self.getIdSpecificPropertyArray[0].contractBSewerInformation,
          //     Waterinformation:
          //       self.getIdSpecificPropertyArray[0].contractBWaterInformation,
          //     Highilight1:
          //       self.getIdSpecificPropertyArray[0].contractBHighlight1,
          //     Highilight2:
          //       self.getIdSpecificPropertyArray[0].contractBHighlight2,
          //     Othercomments:
          //       self.getIdSpecificPropertyArray[0].contractBOtherComments,
          //     // Areyoutheowner:self.getIdSpecificPropertyArray[0],
          //     Nameoftheowner:
          //       self.getIdSpecificPropertyArray[0].contractAOwnerNameOnTitle,
          //     Statewerethecompanywasformed:
          //       self.getIdSpecificPropertyArray[0]
          //         .contractAPurchaseContractSignedByCompanyStateCompanyFormedIn,
          //     Listingonbehalfoftheseller:
          //       self.getIdSpecificPropertyArray[0]
          //         .contractAIsPurchaseContractSignedByCompanyStateCompanyFormedIn,
          //   };
          // } else if (
          //   self.getIdSpecificPropertyArray[0].listingType === "mental_or_detox"
          // ) {
          //   self.propertyDetailObject = {
          //     id: self.getIdSpecificPropertyArray[0].id,
          //     Fullname:
          //       index != -1
          //         ? `${self.allUserData[index].firstName} ${self.allUserData[index].lastName}`
          //         : "",
          //     Companyname:
          //       index != -1 ? self.allUserData[index].companyName : "",
          //     Contactnumber:
          //       index != -1 ? self.allUserData[index].phoneNumber : "",
          //     Latitude: self.getIdSpecificPropertyArray[0].ownerBLatitude,
          //     Longitude: self.getIdSpecificPropertyArray[0].ownerBLongitude,
          //     Roof: self.getIdSpecificPropertyArray[0].ownerBRoof,
          //     HAVC: self.getIdSpecificPropertyArray[0].ownerBHVAC,
          //     Flooring: self.getIdSpecificPropertyArray[0].ownerBFlooring,
          //     Windows: self.getIdSpecificPropertyArray[0].ownerBWindows,
          //     Plumbing: self.getIdSpecificPropertyArray[0].ownerBPlumbing,
          //     Electrical: self.getIdSpecificPropertyArray[0].ownerBElectrical,
          //     Parkingfeature:
          //       self.getIdSpecificPropertyArray[0].ownerBParkingFeatures,
          //     Foundation: self.getIdSpecificPropertyArray[0].ownerBFoundation,
          //     Sewerinformation:
          //       self.getIdSpecificPropertyArray[0].ownerBSewerInformation,
          //     Waterinformation:
          //       self.getIdSpecificPropertyArray[0].ownerBWaterInformation,
          //     Highilight1: self.getIdSpecificPropertyArray[0].ownerBHighlight1,
          //     Highilight2: self.getIdSpecificPropertyArray[0].ownerBHighlight2,
          //     Othercomments:
          //       self.getIdSpecificPropertyArray[0].ownerBOtherComments,
          //     // Areyoutheowner:self.getIdSpecificPropertyArray[0],
          //     Nameoftheowner:
          //       self.getIdSpecificPropertyArray[0]
          //         .ownerACompanyOnTitleToTheProperty,
          //     Statewerethecompanywasformed:
          //       self.getIdSpecificPropertyArray[0]
          //         .ownerAStateOfCompanyIfOwneByACompany,
          //     Listingonbehalfoftheseller:
          //       self.getIdSpecificPropertyArray[0]
          //         .ownerAIsOwnedByCompanyStateWhereCompanyFormedIn,
          //   };
          // } 
          if (
            self.getIdSpecificPropertyArray[0].listingType ===
            "half_way_or_shared" || self.getIdSpecificPropertyArray[0].listingType == 'mental_or_detox_facility' || self.getIdSpecificPropertyArray[0].listingType == 'free_or_public_shelter'
          ) {
            self.propertyDetailObject = {
              id: self.getIdSpecificPropertyArray[0].id,
              Fullname:
                index != -1
                  ? `${self.allUserData[index].firstName} ${self.allUserData[index].lastName}`
                  : "",
              Latitude: self.getIdSpecificPropertyArray[0].leaseBLatitude,
              Longitude: self.getIdSpecificPropertyArray[0].leaseBLongitude,
              nameOfOwner: self.getIdSpecificPropertyArray[0].leaseAShelterName ? self.getIdSpecificPropertyArray[0].leaseAShelterName : "N/A",
              pointOfContact: self.getIdSpecificPropertyArray[0].leaseALanderNameOnTitle ? self.getIdSpecificPropertyArray[0].leaseALanderNameOnTitle : "N/A",
              bathroomType: self.getIdSpecificPropertyArray[0].leaseBBathroomType ? self.getIdSpecificPropertyArray[0].leaseBBathroomType : "N/A",
              phoneNumberForpoc: self.getIdSpecificPropertyArray[0].leaseALanderBestPhoneNumber ? self.getIdSpecificPropertyArray[0].leaseALanderBestPhoneNumber : "N/A",
              // Areyoutheowner:self.getIdSpecificPropertyArray[0],
              Nameoftheowner:
                self.getIdSpecificPropertyArray[0].leaseALanderNameOnTitle,
              fullyExecuted: self.getIdSpecificPropertyArray[0].leaseAIsPropertyHasMorgageNoteOrLeaseOption ? self.getIdSpecificPropertyArray[0].leaseAIsPropertyHasMorgageNoteOrLeaseOption : false,
              isOpreter: self.getIdSpecificPropertyArray[0].leaseAIsMortgageNoteOwnedByCompanyOperatorsOfTheCompany ? self.getIdSpecificPropertyArray[0].leaseAIsMortgageNoteOwnedByCompanyOperatorsOfTheCompany : false,
              iscompanyFormed: self.getIdSpecificPropertyArray[0].leaseAIsMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn ? self.getIdSpecificPropertyArray[0].leaseAIsMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn : false,
              opretor: self.getIdSpecificPropertyArray[0].leaseAMortgageNoteOwnedByCompanyOperatorsOfTheCompany ? self.getIdSpecificPropertyArray[0].leaseAMortgageNoteOwnedByCompanyOperatorsOfTheCompany : "N/A",
              companyFormed: self.getIdSpecificPropertyArray[0].leaseAMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn ? self.getIdSpecificPropertyArray[0].leaseAMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn : "N/A",

              listingType:self.getIdSpecificPropertyArray[0].listingType,
              entryFee: self.getIdSpecificPropertyArray[0].leaseBEntryFee ? self.getIdSpecificPropertyArray[0].leaseBEntryFee : "N/A",
              paymentMethod: self.getIdSpecificPropertyArray[0].leaseBPaymentMethod ? self.getIdSpecificPropertyArray[0].leaseBPaymentMethod : [],
              age: self.getIdSpecificPropertyArray[0].leaseBAge ? self.getIdSpecificPropertyArray[0].leaseBAge : [],
              los: self.getIdSpecificPropertyArray[0].leaseBLengthOfStay ? self.getIdSpecificPropertyArray[0].leaseBLengthOfStay : [],
              gender: self.getIdSpecificPropertyArray[0].leaseBGender ? self.getIdSpecificPropertyArray[0].leaseBGender : [],
           };
          }
          self.showSpinner = false;
        })
        .catch((error) => {
          console.log("ERROR in get property other detail data", error);
          self.showSpinner = false;
        });
    });
  },

  methods: {
    ...mapActions({
      idSpecificPropertyData: "property/idSpecificPropertyData",
    }),
    ...mapActions({ getUserData: "user/userData" }),
  },

  computed: {
    ...mapGetters({
      getIdSpecificPropertyArray: "property/getIdSpecificProperty",
      allUserData: "user/allUserData",
    }),
  },
};
</script>
<style>
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>