<template>
  <div>
    <b-row>
      <b-col md="12" class="listingMgntDetail" v-if="propertyDetailObject.listingType == 'half_way_or_shared' || propertyDetailObject.listingType == 'mental_or_detox_facility' || propertyDetailObject.listingType == 'free_or_public_shelter'">
        <b-card>
          <b-row class="userDetailWrapper listingMgntDetail">
            <b-col xl="1">
              <b-col xl="12" class="p-0">
                <!-- {{propertyDetailObject.PropertyProfile.length}} -->
                <div
                  class="user_img"
                  v-if="
                    propertyDetailObject.PropertyProfile &&
                    propertyDetailObject.PropertyProfile[0] &&
                    propertyDetailObject.PropertyProfile[0] !== '' &&
                    propertyDetailObject.PropertyProfile[0] !== null
                  "
                >
                  <b-img
                    :src="propertyDetailObject.PropertyProfile[0]"
                    alt="User image"
                    style="width: 149px; height: 149px;max-width: 100%;"
                    rounded
                  />
                    <div class="morephotos_area">
                      <ul>
                        <li class="" v-if="propertyDetailObject.PropertyProfile.length >1">
                           <b-img
                              :src="propertyDetailObject.PropertyProfile[1]"
                              alt="User image"
                              style="width: 100%; height: 100%"
                              rounded
                            />
                        </li>
                        <li class="" v-if="propertyDetailObject.PropertyProfile.length >2">
                          <b-img
                            :src="propertyDetailObject.PropertyProfile[2]"
                            alt="User image"
                            style="width: 100%; height: 100%"
                            rounded
                          />
                        </li>
                        <li class="" v-if="propertyDetailObject.PropertyProfile.length >3">
                          <b-img
                            :src="propertyDetailObject.PropertyProfile[3]"
                            alt="User image"
                            style="width: 100%; height: 100%"
                            rounded
                          />
                        </li>
                      </ul>
                      <!-- <p><span>11+</span>More photos</p> -->
                      <p v-if="propertyDetailObject.PropertyProfile.length > 4"><span style="cursor:default !important;">{{propertyDetailObject.PropertyProfile.length-4}} </span>More photos</p>
                      <p v-else></p>
                  </div>
                </div>
                <div class="user_img" v-else>
                  <b-img
                    src="../../../assets/images/avatars/defaultUserProfile.jpg"
                    alt="User image"
                    style="width: 149px; height: 149px"
                    rounded
                  />
                </div>
              </b-col>
            </b-col>
            <b-col xl="11">
              <b-row>
                <b-col xl="3" lg="3" md="4">
                  <div>
                      <h6>Housing Type</h6>
                      <p>{{propertyDetailObject.housingType}}</p>
                  </div>
                  <h5 class="bold">Indoor Living Square Foot</h5>
                  <!-- <p>
                    {{
                      propertyDetailObject.IndoorLivingSquareFoot
                        ? propertyDetailObject.IndoorLivingSquareFoot+' Sq Ft'
                        : "N/A"
                    }}
                  </p> -->
                  <p :class="{sqrtCount:propertyDetailObject.IndoorLivingSquareFoot !== 'N/A'}">
                    {{
                      propertyDetailObject.IndoorLivingSquareFoot
                        ? propertyDetailObject.IndoorLivingSquareFoot
                        : "N/A"
                    }}
                    <span>{{propertyDetailObject.IndoorLivingSquareFoot == 'N/A' ? '':' Sq Ft'}}</span>
                  </p>
                     <h5 class="bold">Lot Size</h5>
                  <!-- <p>
                    {{
                      propertyDetailObject.LotSize
                        ? propertyDetailObject.LotSize
                        : "N/A"
                    }}
                  </p> -->
                  <p :class="{sqrtCount:propertyDetailObject.LotSize !== 'N/A'}">
                    {{
                      propertyDetailObject.LotSize
                        ? propertyDetailObject.LotSize
                        : "N/A"
                    }}
                  </p>
                     <h5 class="bold">Description</h5>
                    <p v-if="propertyDetailObject.Description" v-html="propertyDetailObject.Description">
                    </p>
                  <p v-else>N/A</p>
                  <!-- <p>
                    {{
                      propertyDetailObject.Description
                        ? propertyDetailObject.Description
                        : "N/A"
                    }}
                  </p> -->
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Property ID</h5>
                  <p>
                    {{
                      propertyDetailObject.PropertyId
                        ? propertyDetailObject.PropertyId
                        : "N/A"
                    }}
                  </p>
                  <h5 class="bold">Bedroom Count</h5>
                  <p style="text-transform: capitalize !important">
                    {{
                      propertyDetailObject.BedroomCount
                        ? propertyDetailObject.BedroomCount
                        : "N/A"
                    }}
                  </p>
                 
                  <h5 class="bold">Year Updated</h5>
                  <p style="text-transform: capitalize !important">
                    {{
                      propertyDetailObject.YearBuilt
                        ? propertyDetailObject.YearBuilt
                        : "N/A"
                    }}
                  </p>
                 <h5 class="bold">Intake Instructions</h5>
                  <p style="text-transform: capitalize !important">
                    {{
                      propertyDetailObject.showingInstructions
                        ? propertyDetailObject.showingInstructions
                        : "N/A"
                    }}
                  </p>
                </b-col>
                <b-col xl="3" lg="3" md="4">
                   <h5 class="bold">Property Location</h5>
                  <p style="text-transform: capitalize !important">
                  {{
                    propertyDetailObject.PropertyLocation
                      ? propertyDetailObject.PropertyLocation
                      : "N/A"
                  }}
                  </p>
                  <h5 class="bold">Bathroom Count</h5>
                  <p>
                    {{
                      propertyDetailObject.BathRoomCount
                        ? propertyDetailObject.BathRoomCount
                        : "N/A"
                    }}
                  </p>
                  <h5 class="bold">Latitude (optional)</h5>
                   <p>
                    {{
                      propertyDetailObject.latittude
                        ? propertyDetailObject.latittude
                        : "N/A"
                    }}
                  </p>
                <h5 class="bold">Status</h5>
                  <p style="text-transform: capitalize !important" :class="propertyDetailObject.Status">
                     <!-- <span class="status-area">
                    {{
                      propertyDetailObject.Status
                        ? propertyDetailObject.Status
                        : "N/A"
                    }}
                  </span> -->
                    <b-dropdown
                      v-if="propertyDetailObject.isArchived == false"
                      :text="propertyStatus[propertyDetailObject.status-2].displayName"
                      :class="propertyStatus[propertyDetailObject.status - 2].displayName"
                      style="text-transform: capitalize !important;"
                      class="text-capital"
                    >
                    <b-dropdown-item v-for="(statusP) in propertyStatus.filter((e)=>{return e.displayName !== 'pending'})" :key="statusP.key" @click="openStatusModel(statusP.key)">{{statusP.displayName}}</b-dropdown-item>
                    </b-dropdown>
                    <span v-if="propertyDetailObject.isArchived == true" style="text-transform: capitalize !important;">
                      Archived
                    </span>
                   
                  </p>
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Property Type</h5>
                  <p>
                  {{
                    propertyDetailObject.PropertyType
                      ? propertyDetailObject.PropertyType.join(', ')
                      : "N/A"
                  }}
                  </p>
                    <h5 class="bold">Garage Count</h5>
                  <p>
                  {{
                    propertyDetailObject.GaregeCount
                      ? propertyDetailObject.GaregeCount
                      : "N/A"
                  }}
                  </p>
                  <h5 class="bold">Longitude (optional)</h5>
                   <p>
                    {{
                      propertyDetailObject.longitude
                        ? propertyDetailObject.longitude
                        : "N/A"
                    }}
                  </p>
                  
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="listMgntRow">
      <b-col md="2" class="listingMgntTebLeftSide">
        <b-col md="12">
          <b-card>
            <b-row>
              <b-col
                md="12"
                sm="12"
                class="my-1"
                @click="activeList = 'Other Property Details'"
                :class="{ activeTab: activeList == 'Other Property Details' }"
              >
                <p style="cursor: pointer">Other Property Details</p>
              </b-col>
              <b-col
                md="12"
                sm="12"
                class="my-1"
                @click="activeList = 'Landing Information'"
                :class="{ activeTab: activeList == 'Landing Information' }"
              >
                <p style="cursor: pointer">Features</p>
              </b-col>
              <b-col
                md="12"
                sm="12"
                class="my-1"
                @click="activeList = 'Asking Price & Income Potential'"
                :class="{
                  activeTab: activeList == 'Asking Price & Income Potential',
                }"
              >
                <p style="cursor: pointer">Vacancies</p>
              </b-col>
              <b-col
                md="12"
                sm="12"
                class="my-1"
                @click="activeList = 'Photos/Videos/Documentation'"
                :class="{
                  activeTab: activeList == 'Photos/Videos/Documentation',
                }"
              >
                <p style="cursor: pointer">Photos / Documentation</p>
              </b-col>
              <b-col
                md="12"
                sm="12"
                class="my-1"
                @click="activeList = 'Subscripation Details'"
                :class="{ activeTab: activeList == 'Subscripation Details' }"
              >
                <p style="cursor: pointer">Subscription Details</p>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-col>
      <b-col md="10" class="listingMgntTebRightSide">
        <b-col md="12" v-if="activeList == 'Other Property Details'">
          <OtherPropertyDetail />
        </b-col>
        <b-col md="12" v-if="activeList == 'Landing Information'">
          <LandingInformation />
        </b-col>
        <b-col md="12" v-if="activeList == 'Asking Price & Income Potential'">
          <AskingPriceAndIncomePotential />
        </b-col>
        <b-col md="12" v-if="activeList == 'Photos/Videos/Documentation'">
          <PhotosVideosDocumentation />
        </b-col>
        <b-col md="12" v-if="activeList == 'Subscripation Details'">
          <SubscriptionDetail />
        </b-col>
      </b-col>
    </b-row>
    <b-modal
      id="modal-changeStatus"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="changeStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Are you sure you want to update the status?</h3>
    </b-modal>
    <b-modal
      id="modal-property-status"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="checkForModalOpen"
      @cancel="cancelStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>{{statusChangeModalText}}</h3>
    </b-modal>
    <b-modal
      id="modal-warning-status"
      ok-only
      centered
      hide-footer
    >
      <h3>You can't change property staus because there is no subscription for this property</h3>
    </b-modal>
    <b-modal
      id="modal-cancle-status"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="changeStatusProperty"
      @cancel="cancelChangeStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Changing the status to CANCELLED OR SOLD OR DRAFT will require company contact with the user, to request the paid subscription again. Are you sure you want to change this property status?</h3>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { getCollectionData } from "@/utils/FirebaseQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollections";
import { mapGetters, mapActions,mapMutations } from "vuex";
import { loopFunction } from "@/utils/commonMethods";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OtherPropertyDetail from "@/views/admin/listing-management/otherPropertyDetail";
import LandingInformation from "@/views/admin/listing-management/landingInformation";
import AskingPriceAndIncomePotential from "@/views/admin/listing-management/askingPrice&IncomePotential";
import PhotosVideosDocumentation from "@/views/admin/listing-management/photosvideiosDocumentation";
import SubscriptionDetail from "@/views/admin/listing-management/subscriptiondetail";
import * as updateTypesense from "@/utils/TypeSenseQueries/updateQueries.js";
import axios from "axios";
import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()
export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    CardStatisticProfitChart,
    ToastificationContent,
    OtherPropertyDetail,
    LandingInformation,
    AskingPriceAndIncomePotential,
    PhotosVideosDocumentation,
    SubscriptionDetail,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: false,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "UserId",
          label: "User ID",
        },
        {
          key: "FullName",
          label: "Full Name",
        },
        {
          key: "CompanyName",
          label: "Company Name",
        },
        {
          key: "UserEmail",
          label: "User Email",
        },
        {
          key: "UserMobileNumber",
          label: "User Mobile Number",
        },
        {
          key: "UserType",
          label: "User Type",
        },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "Action",
          label: "Action",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      userType: null,
      status: null,
      propertyDetailObject: {},
      activeList: "Other Property Details",
      updatedStatus: {},
      selectedProperty:{},
      statusChangeModalText:'',
      isCanclleStatus: false
    };
  },

  created() {
    var self = this;
    self.propertyDetailObject = {};
    self.idSpecificPropertyData(self.$route.params.id).then(() => {
      // console.log("propertyStatusArray", self.propertyStatusArray);
      // if (
      //   self.getIdSpecificPropertyArray[0].listingType ===
      //   "free_or_public"
      // ) {
      //   self.propertyDetailObject = {
      //     id: self.getIdSpecificPropertyArray[0].id,
      //     PropertyProfile:
      //       self.getIdSpecificPropertyArray[0].contractFUploadPhotos,
      //     PropertyId: self.getIdSpecificPropertyArray[0].propertyId,
      //     PropertyLocation: `${
      //       self.getIdSpecificPropertyArray[0].contractBStreetNumber
      //         ? self.getIdSpecificPropertyArray[0].contractBStreetNumber
      //         : ""
      //     }${
      //       self.getIdSpecificPropertyArray[0].contractBStreetName
      //         ? self.getIdSpecificPropertyArray[0].contractBStreetName + ","
      //         : ""
      //     }${
      //       self.getIdSpecificPropertyArray[0].contractBUnitorLot
      //         ? self.getIdSpecificPropertyArray[0].contractBUnitorLot + ","
      //         : ""
      //     }${self.getIdSpecificPropertyArray[0].contractBCity},${
      //       self.getIdSpecificPropertyArray[0].contractBState
      //     },${self.getIdSpecificPropertyArray[0].contractBCountry}${
      //       self.getIdSpecificPropertyArray[0].contractBZipCode
      //     }`,
      //     PropertyType: self.getIdSpecificPropertyArray[0].contractBSelectPropertyType,
           
      //     IndoorLivingSquareFoot:
      //       self.getIdSpecificPropertyArray[0].contractBIndoorLivingSquareFoot,
      //     BedroomCount:
      //       self.getIdSpecificPropertyArray[0].contractBBedroomCount,
      //     BathRoomCount:
      //       self.getIdSpecificPropertyArray[0].contractBBathroomCount,
      //     GaregeCount:
      //       self.getIdSpecificPropertyArray[0].contractBGarageCarCount,
      //     LotSize: self.getIdSpecificPropertyArray[0].contractBLotSize,
      //     YearBuilt: self.getIdSpecificPropertyArray[0].contractBYearBuilt,
      //     Description:
      //       self.getIdSpecificPropertyArray[0]
      //         .contractBDescriptionOfAnyRecentRepairs,
      //     Status:
      //       self.propertyStatusArray[
      //         self.getIdSpecificPropertyArray[0].status - 1
      //       ].displayName,
      //   };
      // } else if (
      //   self.getIdSpecificPropertyArray[0].listingType === "mental_or_detox"
      // ) {
      //   self.propertyDetailObject = {
      //     id: self.getIdSpecificPropertyArray[0].id,
      //     PropertyProfile:
      //       self.getIdSpecificPropertyArray[0].ownerFUploadPhotos,
      //     PropertyId: self.getIdSpecificPropertyArray[0].propertyId,
      //     PropertyLocation: `${
      //       self.getIdSpecificPropertyArray[0].ownerBStreetNumber
      //         ? self.getIdSpecificPropertyArray[0].ownerBStreetNumber
      //         : ""
      //     }${
      //       self.getIdSpecificPropertyArray[0].ownerBStreetName
      //         ? self.getIdSpecificPropertyArray[0].ownerBStreetName + ","
      //         : ""
      //     }${
      //       self.getIdSpecificPropertyArray[0].ownerBUnitorLot
      //         ? self.getIdSpecificPropertyArray[0].ownerBUnitorLot + ","
      //         : "" 
      //     }${self.getIdSpecificPropertyArray[0].ownerBCity},${
      //       self.getIdSpecificPropertyArray[0].ownerBState
      //     },${self.getIdSpecificPropertyArray[0].ownerBCountry}${
      //       self.getIdSpecificPropertyArray[0].ownerBZipCode
      //     }`,
      //     PropertyType: self.getIdSpecificPropertyArray[0].ownerBSelectPropertyType,
      //     IndoorLivingSquareFoot:
      //       self.getIdSpecificPropertyArray[0].ownerBIndoorLivingSquareFoot,
      //     BedroomCount: self.getIdSpecificPropertyArray[0].ownerBBedroomCount,
      //     BathRoomCount: self.getIdSpecificPropertyArray[0].ownerBBathroomCount,
      //     GaregeCount: self.getIdSpecificPropertyArray[0].ownerBGarageCarCount,
      //     LotSize: self.getIdSpecificPropertyArray[0].ownerBLotSize,
      //     YearBuilt: self.getIdSpecificPropertyArray[0].ownerBYearBuilt,
      //     Description: self.getIdSpecificPropertyArray[0].ownerBDescription,
      //     Status:
      //       self.propertyStatusArray[
      //         self.getIdSpecificPropertyArray[0].status - 1
      //       ].displayName,
      //   };
      // } 
      if (
        self.getIdSpecificPropertyArray[0].listingType === "half_way_or_shared" || self.getIdSpecificPropertyArray[0].listingType == 'mental_or_detox_facility' || self.getIdSpecificPropertyArray[0].listingType == 'free_or_public_shelter'
      ) {
        self.propertyDetailObject = {
          id: self.getIdSpecificPropertyArray[0].id,
          status:self.getIdSpecificPropertyArray[0].status,
          subscription: self.getIdSpecificPropertyArray[0].subscription,
          subscriptionStatus: self.getIdSpecificPropertyArray[0].subscriptionStatus,
          PropertyProfile:
            self.getIdSpecificPropertyArray[0].leaseFUploadPhotos,
          PropertyId: self.getIdSpecificPropertyArray[0].propertyId,

          PropertyLocation: `${
            self.getIdSpecificPropertyArray[0].leaseAStreetNumber
              ? self.getIdSpecificPropertyArray[0].leaseAStreetNumber
              : ""
          } ${
            self.getIdSpecificPropertyArray[0].leaseAStreetName
              ? self.getIdSpecificPropertyArray[0].leaseAStreetName + ","
              : ""
          } ${
            self.getIdSpecificPropertyArray[0].leaseAUnitorLot
              ? self.getIdSpecificPropertyArray[0].leaseAUnitorLot + ","
              : ""
          } ${self.getIdSpecificPropertyArray[0].leaseBCity}, ${
            self.getIdSpecificPropertyArray[0].leaseBState
          }, ${self.getIdSpecificPropertyArray[0].leaseBCountry}, ${
            self.getIdSpecificPropertyArray[0].leaseBZipCode
          }`,
          PropertyType: self.getIdSpecificPropertyArray[0].leaseBSelectPropertyType,
          IndoorLivingSquareFoot:
            self.getIdSpecificPropertyArray[0].leaseBIndoorLivingSquareFoot,
          housingType: self.getIdSpecificPropertyArray[0].leaseBHouseingType ? self.getIdSpecificPropertyArray[0].leaseBHouseingType : "N/A",
          BedroomCount: self.getIdSpecificPropertyArray[0].leaseBBedroomCount,
          BathRoomCount: self.getIdSpecificPropertyArray[0].leaseBBathroomCount,
          GaregeCount: self.getIdSpecificPropertyArray[0].leaseBGarageCarCount,
          LotSize: self.getIdSpecificPropertyArray[0].leaseBLotSize,
          YearBuilt: self.getIdSpecificPropertyArray[0].leaseBYearBuilt,
          Description: self.getIdSpecificPropertyArray[0].leaseBDescription,
          Status:
            self.propertyStatus[
              self.getIdSpecificPropertyArray[0].status - 2
            ].displayName,
          showingInstructions: self.getIdSpecificPropertyArray[0].leaseBShowingInstructions ? self.getIdSpecificPropertyArray[0].leaseBShowingInstructions : "N/A",
          latittude:self.getIdSpecificPropertyArray[0].leaseBLatitude ?self.getIdSpecificPropertyArray[0].leaseBLatitude : "N/A",
          longitude:self.getIdSpecificPropertyArray[0].leaseBLongitude ?self.getIdSpecificPropertyArray[0].leaseBLongitude : "N/A",
          listingType:self.getIdSpecificPropertyArray[0].listingType,
          isArchived : self.getIdSpecificPropertyArray[0].isArchived ? self.getIdSpecificPropertyArray[0].isArchived :false
        };
      }
      self.$root.$emit("listingDetailPage", `${self.propertyDetailObject.PropertyLocation}`);
    });
  },

  methods: {
    ...mapActions({
      idSpecificPropertyData: "property/idSpecificPropertyData",
    }),
    ...mapMutations({
        updateAllPropertyArray:"property/updateAllPropertyArray"
    }),
    onFiltered() {
      this.totalRows = getIdSpecificPropertyArray.length;
      this.currentPage = 1;
    },
    // commaSeprateUserType(statusArray) {
    //   var self = this;
    //   self.checkAllApply.forEach((data) => {
    //     statusArray.forEach((arr, index) => {
    //       if (arr == data.key) {
    //         statusArray[index] = data.displayName;
    //       }
    //     });
    //   });
    //   return statusArray;
    // },
    // generateUid(uid) {
    //   try {
    //     var result = "";
    //     var characters = uid;
    //     var charactersLength = characters.length;
    //     for (var i = 0; i < 7; i++) {
    //       result += characters.charAt(
    //         Math.floor(Math.random() * charactersLength)
    //       );
    //     }
    //     return result;
    //   } catch (error) {
    //     console.log(error, "?");
    //   }
    // },
    openModel(data) {
      this.updatedStatus = data;
      this.$bvModal.show(`modal-changeStatus`);
    },
    changeStatus() {
      var self = this;
      self.showSpinner = true;
      let data = self.updatedStatus;
      self.propertyDetailObject.Status = data.text;
      let updateObject = {
        status: data.key,
      };
      try {
        updateFirebase.updateRootCollectionDataByDocId(
          dbCollections.PROPERTY,
          self.$route.params.id,
          updateObject,
          (res) => {
            self.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Property status updated successfully",
                variant: "success",
                icon: "",
              },
            });
            setTimeout(() => {
              self.showSpinner = false;
            }, 2000);
          }
        );
      } catch {
        console.log("ERROR in update status in user management");
      }
    },
    openStatusModel(key){
      try {
        this.selectedProperty = {
          key: key,
          item: this.propertyDetailObject
        }
        console.log("this.selectedProperty", this.selectedProperty)
        if(this.selectedProperty.key !== this.selectedProperty.item.status) {
          // this.$bvModal.show(`modal-property-status`);
          this.statusChangeModalText = 'Are you sure you want to change this property status?';
          if(this.selectedProperty.item.status == 2 || this.selectedProperty.item.status == 6) {
              if(this.selectedProperty.key === 2 || this.selectedProperty.key == 6) {
                  // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
                  this.$bvModal.show(`modal-property-status`);
              }else if(this.selectedProperty.key === 3 || this.selectedProperty.key == 4) {
                  this.isCanclleStatus = true;
                  this.isSecondModal = true;
                  // this.statusChangeModalText = 'Are you sure you want to make this property status sold? It will cancle the subscription of the property';
                  this.$bvModal.show(`modal-property-status`);
              } else if(this.selectedProperty.key == 5) {
                  this.isCanclleStatus = true;
                  this.isSecondModal = true;
                  // this.statusChangeModalText = 'Are you sure you want to make this property status canclled? It will cancle the subscription of the property';
                  this.$bvModal.show(`modal-property-status`);
              }
          } 
          // else if(this.selectedProperty.item.status == 3) {
          //     if(this.selectedProperty.key === 2 || this.selectedProperty.key ===  6) {
          //         if(this.selectedProperty.item.subscriptionStatus !== 1){
          //           //warning can't change status
          //           this.$bvModal.show('modal-warning-status');
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     }else if(this.selectedProperty.key == 4 ) {
          //         this.isSecondModal = true;
          //         if(this.selectedProperty.item.subscriptionStatus === 1){
          //             this.isCanclleStatus = true;
          //             // this.statusChangeModalText = 'Are you sure you want to change this property staus to sold? It will cancle the subscription of the property';
          //             this.$bvModal.show(`modal-property-status`);
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     } else if(this.selectedProperty.key == 5) {
          //         this.isSecondModal = true;
          //         if(this.selectedProperty.item.subscriptionStatus === 1){
          //             this.isCanclleStatus = true;
          //             // this.statusChangeModalText = 'Are you sure you want to change this property staus to canclled? It will cancle the subscription of the property';
          //             this.$bvModal.show(`modal-property-status`);
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     }
          // }  
          else if(this.selectedProperty.item.status == 5 || this.selectedProperty.item.status == 4 || this.selectedProperty.item.status == 3) {
                if(this.selectedProperty.key === 2 || this.selectedProperty.key === 6) {
                   //warning can't change status
                   this.$bvModal.show('modal-warning-status');
                } else if(this.selectedProperty.key == 3 ) {
                    // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
                    this.$bvModal.show(`modal-property-status`);
                } else if(this.selectedProperty.key == 4) {
                    this.isSecondModal = true;
                    this.$bvModal.show(`modal-property-status`);
                }  else if(this.selectedProperty.key == 5) {
                    this.isSecondModal = true;
                    this.$bvModal.show(`modal-property-status`);
                }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    cancelStatus() {
      this.$bvModal.hide('modal-property-status');
      this.selectedProperty = {};
      this.statusChangeModalText = '';
      this.isCanclleStatus = false;
    },
    cancelChangeStatus(){
      this.isSecondModal = false;
      this.isCanclleStatus = false;
      this.selectedProperty = {};
      this.$bvModal.hide('modal-cancle-status');
    },
    checkForModalOpen() {
        if(this.isSecondModal) {
            this.$bvModal.show('modal-cancle-status');
        } else {
            this.changeStatusProperty();
        }
    },
    changeStatusProperty() {
      var self = this;
      self.showSpinner = true;
      if(self.isCanclleStatus) {
          self.cancleSubscripiton();
      } else {
          self.directStatusChange();
      }
    },
    cancleSubscripiton() {
        try {
            axios.post(process.env.VUE_APP_APIURL + '/api/v1/delete-subcription', {
                subcriptionId : this.selectedProperty.item.subscription,
                statusKey: this.selectedProperty.key
            })
            .then((deleteRes) =>{
                if(deleteRes.data.status) {
                    this.directStatusChange();
                } else {
                  console.error(deleteRes.data);
                  this.showSpinner = false;
                }
            })
            .catch((error) => {
                console.error(error);
                this.showSpinner = false;
            })
        } catch (error) {
            console.error(error);
            this.showSpinner = false;
        }
    },
    directStatusChange() {
      try {
          let updateObj = {
              status: this.selectedProperty.key,
              subscriptionStatus: this.selectedProperty.key == 3 ? 4 : this.selectedProperty.key == 4 ? 2 : 3,
              updatedAt: new Date()
          }
          if(this.selectedProperty.key === 4 || this.selectedProperty.key === 5 || this.selectedProperty.key === 3) {
            updateObj.removeDocTime = new Date();
          }
          db.collection(dbCollections.PROPERTY)
            .doc(this.selectedProperty.item.id)
            .update(updateObj)
            .then(() => {
                updateTypesense.updatePropertyCollection(this.selectedProperty.item.id, {status: parseInt(this.selectedProperty.key)}).then(() => {
                    console.log("UPDATED IN TYPESENSE");
                })
                .catch((error) =>{
                    console.error(error);
                })
                this.showSpinner = false;
                this.updateAllPropertyArray({
                  id: this.selectedProperty.item.id,
                  status: this.selectedProperty.key
                });
                this.propertyDetailObject.status = this.selectedProperty.key
                this.selectedProperty = {};
                this.statusChangeModalText = '';
                this.isCanclleStatus = false;
                if(this.isSecondModal) {
                  this.isSecondModal = false;
                }
                this.$bvModal.hide('modal-property-status');
                this.$toast.success("Status updated successfully");
            })
      } catch (error) {
          console.error(error);
          this.showSpinner = false;
      }
    }
  },

  computed: {
    ...mapGetters({
      getIdSpecificPropertyArray: "property/getIdSpecificProperty",
      propertyStatusArray: "admin/statusGetter",
    }),
    propertyStatus() {
      let status = [...this.propertyStatusArray]
      let draftInd = status.findIndex((data) => {
        return data.displayName == "draft"
      })
      if (draftInd>-1) {
        status.splice(draftInd,1)
        return status
      } else {
        return status
      }
    },
    filteredItems() {
      // debugger // eslint-disable-line
      var self = this;
      self.showSpinner = true;
      let arrayOfUserData = [];
      let tmp = self.allUserData;
      try {
        tmp.forEach((data) => {
          var obj = {
            id: data.id,
            UserId: self.generateUid(data.id),
            FullName: `${data.firstName} ${data.lastName}`,
            CompanyName: data.companyName,
            UserEmail: data.email,
            UserMobileNumber: "+" + data.phoneNumber,
            UserType: self
              .commaSeprateUserType(data.checkThatAllApply)
              .join(","),
            Status: data.status
              ? self.usersStatus[data.status - 1].displayName
              : "-",
            Action: "...",
          };
          arrayOfUserData.push(obj);
        });
        if (self.userType != null) {
          if (self.userType) {
            arrayOfUserData = arrayOfUserData.filter((f) =>
              f.UserType.toLowerCase().includes(self.userType.toLowerCase())
            );
          }
        }
        if (self.status != null) {
          if (self.status) {
            arrayOfUserData = arrayOfUserData.filter((f) =>
              f.Status.toLowerCase().includes(self.status.toLowerCase())
            );
          }
        }

        self.totalRows = arrayOfUserData.length;
        self.showSpinner = false;
        return arrayOfUserData;
      } catch {
        self.showSpinner = false;
        console.log("ERROR in get records of userData");
      }
    },
    filteredStatuses() {
      var self = this;
      let arr = [];
      if (self.propertyStatusArray.length) {
        self.propertyStatusArray.forEach((data) => {
          arr.push({
            text: data.displayName,
            value: data.displayName.toLowerCase(),
            key: data.key,
          });
        });
        return arr;
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
.activeTab {
  color: #17D1C6;
  font-weight: 600;
}
.sqrtCount {
  font-weight: 700 !important;
  color: #969696 !important;
}
</style>