<template>
  <div>
     <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="showSpinner"/>
    <div class="photoandvideoWrapper">
      <b-row>
        <h3>Property Photos</h3>
        <div class="dividerBottom"></div>
      </b-row>
      <b-row class="videoRow">
        <!-- <b-col
          xl="6"
          lg="6"
          md="6"
          class="videoLeft uploadImages videoImagessData"
          v-if="propertyDetailObject.videos && propertyDetailObject.videos.length > 0"
        >
          <div class="videoHoverDesign">
                <a target="_blank">
                  <div class="video-overlay"></div>
                  <video
                    controls="controls"
                    class="video-stream"
                    x-webkit-airplay="allow"
                    :src="propertyDetailObject.videos"
                    style="max-width: 100%;object-fit: cover;"
                    @click.prevent="(e) => {e.preventDefault(); playVideo('video')}"
                    :key="videoUrl"
                    :ref="'video'"
                            ></video>
                    <div class="video-details"  v-if="showVideoPlayBtn">
                          <span class="video-hover-image"><img class="videoIconImage" src="../../../assets/images/playIcon.png" @click.prevent="playVideo('video')"/></span>
                    </div>
                 </a>
             </div>
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="6"
          class="videoLeft uploadImages videoImagessData"
          v-else
        >
          No Video
        </b-col> -->
        <b-col
          xl="6"
          lg="6"
          md="6"
          class="videoRight"
          v-if="propertyDetailObject.photos && propertyDetailObject.photos.length > 0"
        >
          <div
            v-for="(photo, index) in propertyDetailObject.photos"
            :key="index"
            style="
              height: 80px;
              width: 80px;
              filter: drop-shadow(2px 2px 16px rgba(0, 0, 0, 0.1));
            "
            class="imageWrapperList"
          >
            <img
              :src="photo"
              alt="img"
              @click="imageZoom(photo)"
              v-b-modal.imageZoom
            />
          </div>
        </b-col>
        <b-col xl="6" lg="6" md="6" class="videoRight" v-else>
          No Photos
        </b-col>
      </b-row>
    </div>
    <div class="documentationWrapper">
      <b-row>
        <h3>Documentation</h3>
        <div class="dividerBottom"></div>
      </b-row>
      <b-row v-if="propertyDetailObject.Documantation && propertyDetailObject.Documantation.length > 0">
        <b-col
          class="docUploadData"
          xl="3"
          lg="3"
          md="3"
          v-for="(doc, index) in propertyDetailObject.Documantation"
          :key="index"
        >
          <p class="docText">#Document</p>
          <p class="docFileType">{{ documentName(doc) }}</p>
          <a @click.prevent="download(doc, documentName(doc))"
            ><img
              src="@/assets/images/svg/downloadLogo.svg"
              class="downloadIcon"
            />Download</a
          >
        </b-col>
      </b-row>
      <b-row v-else> No Document </b-row>
    </div>
    <!-- <b-modal id="imageZoom" centered hide-footer :no-close-on-backdrop="true">
        <img  v-if="imageZoomSrc" :src="imageZoomSrc"/>
    </b-modal> -->
    <b-modal
      id="imageZoom"
      class="photoZoomModel"
      ok-only
      ok-variant="secondary"
      ok-title="Close"
      centered
      :no-close-on-backdrop="true"
      hide-header
    >
      <img v-if="imageZoomSrc" :src="imageZoomSrc" />
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import firebase from "@/utils/firebaseInit";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OtherPropertyDetail from "@/views/admin/listing-management/otherPropertyDetail";
import LandingInformation from "@/views/admin/listing-management/landingInformation";
import AskingPriceAndIncomePotential from "@/views/admin/listing-management/askingPrice&IncomePotential";
import PhotosVideosDocumentation from "@/views/admin/listing-management/photosvideiosDocumentation";
import SubscriptionDetail from "@/views/admin/listing-management/subscriptiondetail";
import { downloadDocument } from "@/utils/commonMethods";
// import axios from 'axios'
export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    CardStatisticProfitChart,
    ToastificationContent,
    OtherPropertyDetail,
    LandingInformation,
    AskingPriceAndIncomePotential,
    PhotosVideosDocumentation,
    SubscriptionDetail,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: false,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "UserId",
          label: "User ID",
        },
        {
          key: "FullName",
          label: "Full Name",
        },
        {
          key: "CompanyName",
          label: "Company Name",
        },
        {
          key: "UserEmail",
          label: "User Email",
        },
        {
          key: "UserMobileNumber",
          label: "User Mobile Number",
        },
        {
          key: "UserType",
          label: "User Type",
        },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "Action",
          label: "Action",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      userType: null,
      status: null,
      propertyDetailObject: {},
      activeList: "Other Property Details",
      updatedStatus: {},
      imageZoomSrc: "",
      showVideoPlayBtn: true,
    };
  },

  created() {
    var self = this;
    self.showSpinner = true;
    self.propertyDetailObject = {};

    self
      .idSpecificPropertyData(self.$route.params.id)
      .then(() => {
        if (
          self.getIdSpecificPropertyArray[0].listingType ===
          "free_or_public"
        ) {
          self.propertyDetailObject = {
            photos: self.getIdSpecificPropertyArray[0].contractFUploadPhotos,
            videos: self.getIdSpecificPropertyArray[0].contractFUploadVideo,
            Documantation:
              self.getIdSpecificPropertyArray[0].contractFUploadSeveralPages,
          };
        } else if (
          self.getIdSpecificPropertyArray[0].listingType === "mental_or_detox"
        ) {
          self.propertyDetailObject = {
            photos: self.getIdSpecificPropertyArray[0].ownerFUploadPhotos,
            videos: self.getIdSpecificPropertyArray[0].ownerFUploadVideo,
            Documantation:
              self.getIdSpecificPropertyArray[0].ownerFUploadSeveralPages,
          };
        } else if (
          self.getIdSpecificPropertyArray[0].listingType ===
          "half_way_or_shared" || self.getIdSpecificPropertyArray[0].listingType == 'mental_or_detox_facility' || self.getIdSpecificPropertyArray[0].listingType == 'free_or_public_shelter'
        ) {
          self.propertyDetailObject = {
            photos: self.getIdSpecificPropertyArray[0].leaseFUploadPhotos,
            videos: self.getIdSpecificPropertyArray[0].leaseFUploadVideo,
            Documantation:
              self.getIdSpecificPropertyArray[0].leaseFUploadSeveralPages,
          };
        }
        setTimeout(() => {
          self.showSpinner = false;
        },1000)
      })
      .catch((error) => {
        self.showSpinner = false;
        console.log("ERROR in get property other detail data", error);
      });
  },

  methods: {
    ...mapActions({
      idSpecificPropertyData: "property/idSpecificPropertyData",
    }),
    ...mapActions({ getUserData: "user/userData" }),
    documentName(doc) {
      var httpsReference = firebase.storage().refFromURL(doc);
      let name = httpsReference.name;
      let arr = name.split("");
      arr.splice(0, 1);
      return arr.join("");
    },
    download(url, name) {
      downloadDocument(url, name);
    },
    imageZoom(photo) {
      var self = this;
      self.imageZoomSrc = photo;
    },
    playVideo(refVar) {
      if(this.showVideoPlayBtn) {
          this.$nextTick(() => {
              this.$refs[refVar].play();
          })
      } else {
          this.$nextTick(() => {
              this.$refs[refVar].pause();
          })
      }
      this.showVideoPlayBtn = !this.showVideoPlayBtn;
    },
  },

  computed: {
    ...mapGetters({
      getIdSpecificPropertyArray: "property/getIdSpecificProperty",
    }),
  },
};
</script>
<style>
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>