<template>
  <div>
    <b-spinner v-if="showSpinner" class="spinner"></b-spinner>
    <div class="contactWrapper askingPriceDetailsWrapper" v-if="propertyDetailObject.listingType == 'half_way_or_shared'  || propertyDetailObject.listingType == 'mental_or_detox_facility' || propertyDetailObject.listingType == 'free_or_public_shelter'">
      <b-row>
        <h3>Vacancies</h3>
        <div class="dividerBottom"></div>
      </b-row>
      <b-row>
        <b-col xl="3" lg="3" md="3">
          <h5 class="bold">Intake Method</h5>
          <p style="text-transform: capitalize !important">
            {{
              propertyDetailObject.intakeMethod
                ? propertyDetailObject.intakeMethod
                : "N/A"
            }}
          </p>
        </b-col>
        <b-col xl="3" lg="3" md="3">
          <h5 class="bold">Last Updated</h5>
          <p style="text-transform: capitalize !important">
            {{
              propertyDetailObject.orginationDate
                ? propertyDetailObject.orginationDate
                : "N/A"
            }}
          </p>
        </b-col>
        <b-col xl="3" lg="3" md="3">
          <h5 class="bold">Capacity</h5>
          <p style="text-transform: capitalize !important">
            {{
              propertyDetailObject.capacity
                ? propertyDetailObject.capacity
                : "N/A"
            }}
          </p>
        </b-col>
        <b-col xl="3" lg="3" md="3">
          <h5 class="bold">Number of Vacancies</h5>
          <p style="text-transform: capitalize !important">
            {{
              propertyDetailObject.numberofVacancies
                ? propertyDetailObject.numberofVacancies
                : "N/A"
            }}
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OtherPropertyDetail from "@/views/admin/listing-management/otherPropertyDetail";
import LandingInformation from "@/views/admin/listing-management/landingInformation";
import AskingPriceAndIncomePotential from "@/views/admin/listing-management/askingPrice&IncomePotential";
import PhotosVideosDocumentation from "@/views/admin/listing-management/photosvideiosDocumentation";
import SubscriptionDetail from "@/views/admin/listing-management/subscriptiondetail";
import moment from 'moment'

export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    CardStatisticProfitChart,
    ToastificationContent,
    OtherPropertyDetail,
    LandingInformation,
    AskingPriceAndIncomePotential,
    PhotosVideosDocumentation,
    SubscriptionDetail,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: false,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "UserId",
          label: "User ID",
        },
        {
          key: "FullName",
          label: "Full Name",
        },
        {
          key: "CompanyName",
          label: "Company Name",
        },
        {
          key: "UserEmail",
          label: "User Email",
        },
        {
          key: "UserMobileNumber",
          label: "User Mobile Number",
        },
        {
          key: "UserType",
          label: "User Type",
        },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "Action",
          label: "Action",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      userType: null,
      status: null,
      propertyDetailObject: {},
      activeList: "Other Property Details",
      updatedStatus: {},
    };
  },

  created() {
    var self = this;
    self.showSpinner = true;
    self.propertyDetailObject = {};

    self
      .idSpecificPropertyData(self.$route.params.id)
      .then(() => {
        // if (
        //   self.getIdSpecificPropertyArray[0].listingType ===
        //   "free_or_public"
        // ) {
        //   self.propertyDetailObject = {
        //     Askingpriceofproperty: self.getIdSpecificPropertyArray[0]
        //       .contractDAskingPrice
        //       ? getCommaSeperatedNumber(
        //           self.getIdSpecificPropertyArray[0].contractDAskingPrice
        //         )
        //       : "",
        //     Valueobtainedusinganeutral3rdpartypricingspecialist:
        //       self.getIdSpecificPropertyArray[0].contractDARV,
        //     Annualgrossrentalincome:
        //       self.getIdSpecificPropertyArray[0]
        //         .contractEAnnualGrossRentalIncome,
        //     Rentalpotential:
        //       self.getIdSpecificPropertyArray[0].contractERentalPotential,
        //     Currentocuupancy:
        //       self.getIdSpecificPropertyArray[0].contractECurrentOccupancy,
        //     listingType: self.getIdSpecificPropertyArray[0].listingType,
        //   };
        // } else if (
        //   self.getIdSpecificPropertyArray[0].listingType === "mental_or_detox"
        // ) {
        //   self.propertyDetailObject = {
        //     Askingpriceofproperty: self.getIdSpecificPropertyArray[0]
        //       .ownerDAskingPrice
        //       ? getCommaSeperatedNumber(
        //           self.getIdSpecificPropertyArray[0].ownerDAskingPrice
        //         )
        //       : "",
        //     Valueobtainedusinganeutral3rdpartypricingspecialist:
        //       self.getIdSpecificPropertyArray[0]
        //         .ownerDIsNeutral3rdPartyPricingSpecialist,
        //     Annualgrossrentalincome:
        //       self.getIdSpecificPropertyArray[0].ownerEAnnualGrossRentalIncome,
        //     Rentalpotential:
        //       self.getIdSpecificPropertyArray[0].ownerERentalPotential,
        //     Currentocuupancy:
        //       self.getIdSpecificPropertyArray[0].ownerECurrentOccupancy,
        //     listingType: self.getIdSpecificPropertyArray[0].listingType,
        //   };
        // }  
        if (
          self.getIdSpecificPropertyArray[0].listingType ===
          "half_way_or_shared" || self.getIdSpecificPropertyArray[0].listingType == 'mental_or_detox_facility' || self.getIdSpecificPropertyArray[0].listingType == 'free_or_public_shelter'
        ) {
          self.propertyDetailObject = {
            orginationDate: self.getIdSpecificPropertyArray[0].leaseDOriginationDate ? moment(self.getIdSpecificPropertyArray[0].leaseDOriginationDate.seconds * 1000).format("MM/DD/YYYY, h:mm:ss a") : "N/A",
            intakeMethod: self.getIdSpecificPropertyArray[0].leaseDIntakeMethod ? self.getIdSpecificPropertyArray[0].leaseDIntakeMethod : "N/A",
            capacity: self.getIdSpecificPropertyArray[0].leaseDCapacity ? self.getIdSpecificPropertyArray[0].leaseDCapacity : "N/A",
            numberofVacancies: self.getIdSpecificPropertyArray[0].leaseDNumberOfVacancies ? self.getIdSpecificPropertyArray[0].leaseDNumberOfVacancies : "N/A",
            listingType: self.getIdSpecificPropertyArray[0].listingType,
          };
        }
        self.showSpinner = false;
      })
      .catch((error) => {
        console.log("ERROR in get property other detail data", error);
      });
  },

  methods: {
    ...mapActions({
      idSpecificPropertyData: "property/idSpecificPropertyData",
    }),
    ...mapActions({ getUserData: "user/userData" }),
  },

  computed: {
    ...mapGetters({
      getIdSpecificPropertyArray: "property/getIdSpecificProperty",
    }),
  },
};
</script>