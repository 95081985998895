import client from '@/utils/TypesenseConfig'
import { dbCollections } from '@/utils/firebaseCollections';

//UPDATE PROPERTY DOC DATA
export const updatePropertyCollection = (id, updateObj) =>{
    return new Promise((resolve, reject)  => {
        try {
            client.collections(dbCollections.TYPESENSE_PROPERTIES).documents(id).update(updateObj).then((resData) => {
                resolve(resData);
            }).catch((error) => {
                reject(error);
            })
        } catch (error) {
            reject(error);
        }
    })
};