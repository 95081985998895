<template>
  <div>
    <b-spinner
      style="width: 50px; height: 50px"
      class="spinner"
      v-if="showSpinner"
    />
      <b-row>
        <b-col md="12" sm="12" class="p-0">
          <h3 class="card-title">Subscription</h3>
          <div class="dividerBottom"></div>
        </b-col>
      </b-row>
    <div v-if="Object.keys(propertyDetailObject).length > 0">
      <div class="statusWrapperDetail">
        <b-row>
          <b-col md="6" sm="6">
            <h3 class="card-title">Status</h3>
          </b-col>
          <b-col md="6" sm="6">
            <span class="card-title">{{ propertyDetailObject.Status }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3" sm="3">
            <label class="card-title">Amount</label>
            <p>{{ propertyDetailObject.SubscriptionAmount }}</p>
          </b-col>
          <b-col md="3" sm="3">
            <label class="card-title">Activation Date</label>
            <p>{{ propertyDetailObject.SubscriptionActivationDate }}</p>
          </b-col>
          <b-col md="3" sm="3">
            <label class="card-title">Auto-renews On</label>
            <p>{{ propertyDetailObject.SubscriptionAutorenewsOnDate }}</p>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else>
        <b-col cols="12" style="width: 100%; text-align: center">
          <p>No records found</p>
        </b-col>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      showSpinner: false,
      propertyDetailObject: {},
    };
  },
  created() {
    var self = this;
    self.showSpinner = true;
    self.propertyDetailObject = {};

    self
      .idSpecificPropertyData(self.$route.params.id)
      .then(() => {
        if(self.getIdSpecificPropertyArray.length > 0){
        // console.log(self.getIdSpecificPropertyArray,'self.getIdSpecificPropertyArray[0]');
        if (
          self.getIdSpecificPropertyArray[0].subscriptionStatus == 1 ||
          self.getIdSpecificPropertyArray[0].subscriptionStatus == 3
        ) {
          if (
            self.getIdSpecificPropertyArray[0].listingType ===
            "free_or_public"
          ) {
            self.propertyDetailObject = {
              SubscriptionAmount: self.getIdSpecificPropertyArray[0]
                .subscriptionAmountIndoller !== undefined
                ? "$" +
                  self.getIdSpecificPropertyArray[0].subscriptionAmountIndoller
                : "",
              SubscriptionActivationDate: self.getIdSpecificPropertyArray[0]
                .subscriptionDate
                ? moment(
                    self.getIdSpecificPropertyArray[0].subscriptionDate
                      .seconds * 1000
                  ).format("MM/DD/YYYY")
                : "",
              SubscriptionAutorenewsOnDate: self.getIdSpecificPropertyArray[0]
                .autoRenewalDate
                ? moment(
                    self.getIdSpecificPropertyArray[0].autoRenewalDate.seconds *
                      1000
                  ).format("MM/DD/YYYY")
                : "",
              Status: self.getIdSpecificPropertyArray[0].subscriptionStatus
                ? self.getSubStatus[
                    self.getIdSpecificPropertyArray[0].subscriptionStatus - 1
                  ].displayName
                : "N/A",
            };
          } else if (
            self.getIdSpecificPropertyArray[0].listingType === "mental_or_detox"
          ) {
            self.propertyDetailObject = {
              SubscriptionAmount: self.getIdSpecificPropertyArray[0]
                .subscriptionAmountIndoller !== undefined
                ? "$" +
                  self.getIdSpecificPropertyArray[0].subscriptionAmountIndoller
                : "",
              SubscriptionActivationDate: self.getIdSpecificPropertyArray[0]
                .subscriptionDate
                ? moment(
                    self.getIdSpecificPropertyArray[0].subscriptionDate
                      .seconds * 1000
                  ).format("MM/DD/YYYY")
                : "",
              SubscriptionAutorenewsOnDate: self.getIdSpecificPropertyArray[0]
                .autoRenewalDate
                ? moment(
                    self.getIdSpecificPropertyArray[0].autoRenewalDate.seconds *
                      1000
                  ).format("MM/DD/YYYY")
                : "",
              Status: self.getIdSpecificPropertyArray[0].subscriptionStatus
                ? self.getSubStatus[
                    self.getIdSpecificPropertyArray[0].subscriptionStatus - 1
                  ].displayName
                : "N/A",
            };
          } else if (
            self.getIdSpecificPropertyArray[0].listingType ===
            "half_way_or_shared" || self.getIdSpecificPropertyArray[0].listingType == 'mental_or_detox_facility' || self.getIdSpecificPropertyArray[0].listingType == 'free_or_public_shelter'
          ) {
            self.propertyDetailObject = {
              SubscriptionAmount: self.getIdSpecificPropertyArray[0]
                .subscriptionAmountIndoller !== undefined
                ? "$" +
                  self.getIdSpecificPropertyArray[0].subscriptionAmountIndoller
                : "",
              SubscriptionActivationDate: self.getIdSpecificPropertyArray[0]
                .subscriptionDate
                ? moment(
                    self.getIdSpecificPropertyArray[0].subscriptionDate
                      .seconds * 1000
                  ).format("MM/DD/YYYY")
                : "",
              SubscriptionAutorenewsOnDate: self.getIdSpecificPropertyArray[0]
                .autoRenewalDate
                ? moment(
                    self.getIdSpecificPropertyArray[0].autoRenewalDate.seconds *
                      1000
                  ).format("MM/DD/YYYY")
                : "",
              Status: self.getIdSpecificPropertyArray[0].subscriptionStatus
                ? self.getSubStatus[
                    self.getIdSpecificPropertyArray[0].subscriptionStatus - 1
                  ].displayName
                : "N/A",
            };
          }
        }
        }else{
          self.showSpinner = false;
        }
        self.showSpinner = false;
      })
      .catch((error) => {
         self.showSpinner = false;
        console.log("ERROR in get property other detail data", error);
      });
  },
  methods: {
    ...mapActions({
      idSpecificPropertyData: "property/idSpecificPropertyData",
    }),
  },
  computed: {
    ...mapGetters({
      getIdSpecificPropertyArray: "property/getIdSpecificProperty",
      getSubStatus: "admin/subScriptionStatus",
    }),
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>