<template>
  <div>
    <b-spinner v-if="showSpinner" class="spinner"></b-spinner>
    <div v-if="propertyDetailObject.listingType == 'half_way_or_shared' || propertyDetailObject.listingType == 'mental_or_detox_facility' || propertyDetailObject.listingType == 'free_or_public_shelter'">
      <div class="contactWrapper">
        <b-row>
          <h3>Features</h3>
          <div class="dividerBottom"></div>
        </b-row>
        <b-row>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Languages Spoken at the Shelter</h5>
            <p>{{ propertyDetailObject.languageSpoken ? propertyDetailObject.languageSpoken : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Security System</h5>
            <p>{{ propertyDetailObject.securitySystem ? propertyDetailObject.securitySystem : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Rooms Can be Locked</h5>
            <p>{{ propertyDetailObject.roomLocked ? propertyDetailObject.roomLocked : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Transportation Provided</h5>
            <p>{{ propertyDetailObject.tProvided ? propertyDetailObject.tProvided : 'N/A' }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Bed and Furniture Provided</h5>
            <p>{{ propertyDetailObject.fProvided ? propertyDetailObject.fProvided : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Food Provided</h5>
            <p>{{ propertyDetailObject.foodProvided ? propertyDetailObject.foodProvided : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Appliances Provided</h5>
            <p>{{ propertyDetailObject.appliancesProvided ? propertyDetailObject.appliancesProvided : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Internet Provided</h5>
            <p>{{ propertyDetailObject.internetProvided ? propertyDetailObject.internetProvided : 'N/A' }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Heating</h5>
            <p>{{ propertyDetailObject.heating ? propertyDetailObject.heating : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Air Conditioning</h5>
            <p>{{ propertyDetailObject.airConditioning ? propertyDetailObject.airConditioning : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Manager Onsite</h5>
            <p>{{ propertyDetailObject.houseManager ? propertyDetailObject.houseManager : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Property Manager</h5>
            <p>{{ propertyDetailObject.propertyManager ? propertyDetailObject.propertyManager : 'N/A' }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Support Services in the Community</h5>
            <p>{{ propertyDetailObject.supportServiceCommunity ? propertyDetailObject.supportServiceCommunity : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Entry-Level Work Nearby</h5>
            <p>{{ propertyDetailObject.entryLevelWork ? propertyDetailObject.entryLevelWork : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Public Transportation Nearby</h5>
            <p>{{ propertyDetailObject.publicTransportationNearby ? propertyDetailObject.publicTransportationNearby : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Valid ID Needed</h5>
            <p>{{ propertyDetailObject.validIdNeeded ? propertyDetailObject.validIdNeeded : 'N/A' }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Background Check</h5>
            <p>{{ propertyDetailObject.backgroundCheck ? propertyDetailObject.backgroundCheck : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Credit Check</h5>
            <p>{{ propertyDetailObject.creditCheck ? propertyDetailObject.creditCheck : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Social Security Number</h5>
            <p>{{ propertyDetailObject.socialSecurityNumber ? propertyDetailObject.socialSecurityNumber : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Can a social worker or police officer verify the identity of the applicant?</h5>
            <p>{{ propertyDetailObject.verifyIdentityApplicant ? propertyDetailObject.verifyIdentityApplicant : 'N/A' }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Charges Pending</h5>
            <p>{{ propertyDetailObject.chargesPending ? propertyDetailObject.chargesPending : 'N/A' }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5 class="bold">Previous Felony</h5>
            <p>{{ propertyDetailObject.previousFelony ? propertyDetailObject.previousFelony : 'N/A' }}</p>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OtherPropertyDetail from "@/views/admin/listing-management/otherPropertyDetail";
import LandingInformation from "@/views/admin/listing-management/landingInformation";
import AskingPriceAndIncomePotential from "@/views/admin/listing-management/askingPrice&IncomePotential";
import PhotosVideosDocumentation from "@/views/admin/listing-management/photosvideiosDocumentation";
import SubscriptionDetail from "@/views/admin/listing-management/subscriptiondetail";
export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    CardStatisticProfitChart,
    ToastificationContent,
    OtherPropertyDetail,
    LandingInformation,
    AskingPriceAndIncomePotential,
    PhotosVideosDocumentation,
    SubscriptionDetail,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: false,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "UserId",
          label: "User ID",
        },
        {
          key: "FullName",
          label: "Full Name",
        },
        {
          key: "CompanyName",
          label: "Company Name",
        },
        {
          key: "UserEmail",
          label: "User Email",
        },
        {
          key: "UserMobileNumber",
          label: "User Mobile Number",
        },
        {
          key: "UserType",
          label: "User Type",
        },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "Action",
          label: "Action",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      userType: null,
      status: null,
      propertyDetailObject: {},
      activeList: "Other Property Details",
      updatedStatus: {},
    };
  },

  created() {
    var self = this;
    self.showSpinner = true;
    self.propertyDetailObject = {};

    self
      .idSpecificPropertyData(self.$route.params.id)
      .then(() => {
        if (
          self.getIdSpecificPropertyArray[0].listingType ===
          "half_way_or_shared" || self.getIdSpecificPropertyArray[0].listingType == 'mental_or_detox_facility' || self.getIdSpecificPropertyArray[0].listingType == 'free_or_public_shelter'
        ) {
          self.propertyDetailObject = {
            languageSpoken: self.getIdSpecificPropertyArray[0].leaseCLanguageSpokenAtShelter ? self.getIdSpecificPropertyArray[0].leaseCLanguageSpokenAtShelter : "N/A",
            securitySystem: self.getIdSpecificPropertyArray[0].leaseCSecuritySystem ? self.getIdSpecificPropertyArray[0].leaseCSecuritySystem : "N/A",
            roomLocked: self.getIdSpecificPropertyArray[0].leaseCSecuritySystem ? self.getIdSpecificPropertyArray[0].leaseCSecuritySystem : "N/A",
            tProvided: self.getIdSpecificPropertyArray[0].leaseCTransportationProvided ? self.getIdSpecificPropertyArray[0].leaseCTransportationProvided : "N/A",
            fProvided: self.getIdSpecificPropertyArray[0].leaseCBedFurnitureProvided ? self.getIdSpecificPropertyArray[0].leaseCBedFurnitureProvided : "N/A",
            foodProvided: self.getIdSpecificPropertyArray[0].leaseCFoodProvided ? self.getIdSpecificPropertyArray[0].leaseCFoodProvided : "N/A",
            appliancesProvided: self.getIdSpecificPropertyArray[0].leaseCAppliancesProvided ? self.getIdSpecificPropertyArray[0].leaseCAppliancesProvided : "N/A",
            internetProvided: self.getIdSpecificPropertyArray[0].leaseCInternetProvided ? self.getIdSpecificPropertyArray[0].leaseCInternetProvided : "N/A",
            heating: self.getIdSpecificPropertyArray[0].leaseCHeating ? self.getIdSpecificPropertyArray[0].leaseCHeating : "N/A",
            airConditioning : self.getIdSpecificPropertyArray[0].leaseCAirConditioning ? self.getIdSpecificPropertyArray[0].leaseCAirConditioning : "N/A",
            houseManager : self.getIdSpecificPropertyArray[0].leaseCHouseManager ? self.getIdSpecificPropertyArray[0].leaseCHouseManager : "N/A",
            propertyManager : self.getIdSpecificPropertyArray[0].leaseCPropertyManager ? self.getIdSpecificPropertyArray[0].leaseCPropertyManager : "N/A",
            supportServiceCommunity : self.getIdSpecificPropertyArray[0].leaseCSupportServiceCommunity ? self.getIdSpecificPropertyArray[0].leaseCSupportServiceCommunity : "N/A",
            entryLevelWork : self.getIdSpecificPropertyArray[0].leaseCEntryLevelWork ? self.getIdSpecificPropertyArray[0].leaseCEntryLevelWork : "N/A",
            publicTransportationNearby : self.getIdSpecificPropertyArray[0].leaseCPublicTrasportation ? self.getIdSpecificPropertyArray[0].leaseCPublicTrasportation : "N/A",
            validIdNeeded : self.getIdSpecificPropertyArray[0].leaseCValidIdNeeded ? self.getIdSpecificPropertyArray[0].leaseCValidIdNeeded : "N/A",
            backgroundCheck : self.getIdSpecificPropertyArray[0].leaseCBackgroundCheck ? self.getIdSpecificPropertyArray[0].leaseCBackgroundCheck : "N/A",
            creditCheck : self.getIdSpecificPropertyArray[0].leaseCCreditCheck ? self.getIdSpecificPropertyArray[0].leaseCCreditCheck : "N/A",
            socialSecurityNumber : self.getIdSpecificPropertyArray[0].leaseCSocialSecurityNumber ? self.getIdSpecificPropertyArray[0].leaseCSocialSecurityNumber : "N/A",
            verifyIdentityApplicant : self.getIdSpecificPropertyArray[0].leaseCVerifyIdentityApplicant ? self.getIdSpecificPropertyArray[0].leaseCVerifyIdentityApplicant : "N/A",
            chargesPending : self.getIdSpecificPropertyArray[0].leaseCChargesPending ? self.getIdSpecificPropertyArray[0].leaseCChargesPending : "N/A",
            previousFelony : self.getIdSpecificPropertyArray[0].leaseCPreviousFelony ? self.getIdSpecificPropertyArray[0].leaseCPreviousFelony : "N/A",
            listingType: self.getIdSpecificPropertyArray[0].listingType,
          };
        }
        self.showSpinner = false;
      })
      .catch((error) => {
        console.log("ERROR in get property other detail data", error);
      });
  },

  methods: {
    ...mapActions({
      idSpecificPropertyData: "property/idSpecificPropertyData",
    }),
    ...mapActions({ getUserData: "user/userData" }),
  },

  computed: {
    ...mapGetters({
      getIdSpecificPropertyArray: "property/getIdSpecificProperty",
    }),
  },
};
</script>